body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


#title {
    text-align: center;
}

.table {
    border-collapse: collapse !important;
    border: 3px solid #ecebeb !important;
    margin: 10px !important;
}

    .table td,
    .table th {
        border: 1px solid #ecebeb;
        padding: 12px;
    }

    .table tbody tr:hover {
        background-color: #ddd;
    }

    .table thead {
        padding: 10px;
        text-align: center;
        background-color: #007bff;
        color: white;
    }

.form-check-input {
    position: relative !important;
    margin-top: 0.3rem;
    margin-left: 0rem;
}

.button {
    border: none;
    outline: none;
    font-size: 11px;
    font-family: "Quicksand", sans-serif;
    color: #f44336;
    padding: 3px 10px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f44336;
    background-color: transparent;
}

    .button:active {
        border: 1px solid blue;
    }

.btnmargin {
    margin: 3px;
}
